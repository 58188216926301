import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { lastValueFrom } from 'rxjs';

import { AuthService } from 'services/auth';
import { CasinoService } from 'services/casino';
import { checkProUser } from 'common/common';

import { ICasinoSchema } from 'appRedux/models/casinoModels';
import { ReduxCommandCreator } from 'appRedux/actions';
import { IUserInfo, useAppSelector } from 'appRedux/createStore';
import { CasinoReduxCommandCreator } from 'appRedux/actions/casinoCommandCreator';

import SmartLoading from 'components/SmartLoading';
import { EditCasinoCard } from 'components/EditCasinoCard/EditCasinoCard';

const slotCheckCasinoId = parseInt(__SLOTCHECK_ID__);

export const EditCasinos: FC = () => {
  const casinoSvc = new CasinoService();
  const auth = new AuthService();
  const history = useHistory();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const casinoCommands = CasinoReduxCommandCreator(dispatch);
  const [selectedCasino, setSelectedCasino] = useState<number>(0);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const { appUserPk: appUserFk } = useAppSelector(
    (state) => state.app.persistedState.userInfo
  ) as IUserInfo;

  const [presentToast] = useIonToast();

  const [isLoading, setIsLoading] = useState(false);
  const [casinos, setCasinos] = useState<ICasinoSchema[]>([]);
  const [activeCasinos, setActiveCasinos] = useState<ICasinoSchema[]>([]);
  const [primaryCasino, setPrimaryCasino] = useState<ICasinoSchema>();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      void fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const casinoResponse = await lastValueFrom(casinoSvc.GetAllCasinos());

      if (casinoResponse) {
        const filteredCasinos = casinoResponse.DataSet.filter(
          (casinoInfo) => casinoInfo.casinoId !== slotCheckCasinoId
        );

        const activeCasinos = filteredCasinos.filter(
          (casinoInfo) => casinoInfo.isactive && !casinoInfo.isComingSoon
        );
        setActiveCasinos(activeCasinos);

        const activeCasinoIds = activeCasinos.map((casinoInfo) => casinoInfo.casinoId);

        // Fetch user info for active casinos
        const userInfo = await lastValueFrom(auth.GetUserInfo(activeCasinoIds));

        if (userInfo) {
          const currentPeriodEnd = userInfo.user.subscription?.subscriptionData.current_period_end;
          const subscriptionActive = userInfo.user.subscription?.subscriptionData.status;
          const isProUser = checkProUser(subscriptionActive, currentPeriodEnd);

          commands.SetUserLevel({
            isProUser: isProUser,
            aliasInitial: userInfo.user.info.aliasInitial,
            aliasInitial2: userInfo.user.info.aliasInitial2,
            aliasName: userInfo.user.info.aliasName,
          });
          casinoCommands.SetLinkedCasinos({
            casinoLinkedAccounts: userInfo.user.linkedAccounts,
          });

          const primaryCasino = userInfo.user.userCasinoList.primaryCasino;
          primaryCasino && setPrimaryCasino(primaryCasino);

          const NonPrimaryCasinos = activeCasinos.filter(
            (casinoInfo) => casinoInfo.casinoId !== primaryCasino.casinoId
          );
          setCasinos(NonPrimaryCasinos);
        }
      }
    } catch (error) {
      presentToast({
        position: 'top',
        duration: 5000,
        color: 'danger',
        message: 'Failed to retrieve casino information.',
      }).catch(() => null);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickCasino = async (casinoDetail: ICasinoSchema) => {
    if (casinoDetail.isComingSoon) {
      return;
    }

    setIsLoading(true);

    const { casinoId } = casinoDetail;

    if (appUserFk) {
      try {
        const data = await casinoSvc.UpdatePrimaryCasinoID(String(casinoId), appUserFk).toPromise();

        if (data) {
          await presentToast({
            position: 'top',
            duration: 5000,
            color: 'success',
            message: 'Primary casino changes saved',
          });

          await fetchData();

          setSelectedCasino(0);
        }
      } catch (error) {
        await presentToast({
          position: 'top',
          duration: 5000,
          color: 'danger',
          message: 'Failed to set new primary casino',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSelectCasino = (value: number) => {
    setSelectedCasino(value);
    setIsSaved(false);
  };

  const handleSaveClick = async () => {
    if (selectedCasino) {
      setIsSaved(true);

      const filteredCasinos = activeCasinos.filter(
        (casinoInfo) => casinoInfo.casinoId === selectedCasino
      );

      if (filteredCasinos.length > 0) {
        await onClickCasino(filteredCasinos[0]);
      }
    }
  };

  const handleCancelClick = () => {
    setSelectedCasino(0);
    history.goBack();
  };

  const renderCasinoItem = (casino: ICasinoSchema, key: string) => {
    const { displayName, casinoId, address } = casino;

    return (
      <IonRow data-testid="choose-casino-item" key={key}>
        <IonCol size="12">
          <EditCasinoCard
            key={casinoId?.toString()}
            name={displayName}
            address={address.displayAddress}
            radioValue={casinoId}
            selectedRadio={selectedCasino}
            onSelect={handleSelectCasino}
            cssClassName={'bg-transparent'}
          />
        </IonCol>
      </IonRow>
    );
  };

  return (
    <IonPage className="app-parent">
      <SmartLoading loading={isLoading} />

      <div className="slotcheck-header light-theme">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => history.goBack()}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>

            <div className="logo header-title">Casinos</div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent>
        {!isLoading && (
          <div className="container">
            <IonGrid className="ion-no-margin m-signup m-signup__choose-casino full-height">
              <div className="choose-casinos my-casinos edit-primary-casino">
                <IonRow>
                  <IonCol>
                    <div className="header-bar small">
                      <h1 className="heading5 heading">Update Primary Casino</h1>
                    </div>
                  </IonCol>
                </IonRow>

                {primaryCasino && (
                  <div className="primary-casino">
                    <div className="primary-casino__label body-small-bold">Primary</div>
                    <div role="heading" aria-level={2} className="primary-casino__title subtitle1">
                      {primaryCasino.displayName}
                    </div>
                    <div className="primary-casino__location body-small">
                      {primaryCasino.address.displayAddress}
                    </div>
                  </div>
                )}

                <hr className="divider" />

                {(casinos || []).map((eachCasino) => {
                  return renderCasinoItem(eachCasino, `casino-id-${eachCasino.casinoId}`);
                })}

                <div className="actions">
                  <IonButton
                    expand="block"
                    color="primary"
                    type="submit"
                    data-testid="submitButton"
                    onClick={handleSaveClick}
                    disabled={!selectedCasino}
                  >
                    {isSaved ? 'Primary Casino Saved' : 'Save Primary Casino'}
                  </IonButton>

                  <IonButton expand="block" color="medium" onClick={handleCancelClick}>
                    Cancel
                  </IonButton>
                </div>
              </div>
            </IonGrid>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
